import * as Sentry from '@sentry/astro';

let environment = '';
const {hostname} = window.location;

if (hostname === 'documents.primecc.com.au') {
  environment = 'prod';
} else if (hostname.includes('test')) {
  environment = 'test';
} else if (hostname.includes('dev')) {
  environment = 'dev';
}

Sentry.init({
  debug: true,
  enabled: !!environment,
  environment,
  dsn: 'https://8a16df5ed9c19544d5815db50ed25c95@o4507767220862976.ingest.de.sentry.io/4507767248781392',
  includeLocalVariables: true,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
      mask: ['.data-sentry-block'],
      networkDetailAllowUrls: [
        // UI and API urls for DEV
        'https://documents-dev.primecc.com.au',
        'https://documents-api-dev.primecc.com.au',
        // UI and API urls for PROD
        'https://documents.primecc.com.au',
        'https://documents-api.primecc.com.au',
      ],
      networkRequestHeaders: ['X-Custom-Header'],
      networkResponseHeaders: ['X-Custom-Header'],
    }),
  ],
});
